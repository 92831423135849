import { Injectable } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { SearchResult, RecentSearch } from './search';
import { ManifestationService } from '../manifestation/manifestation.service';
import { IllnessScriptService } from '../illness-script/illness-script.service';

@Injectable()
export class SearchService {
  private readonly STORAGE_KEY = 'recentSearches';

  constructor(
    private manifestationService: ManifestationService,
    private illnessScriptService: IllnessScriptService
  ) {}

  search(searchText: string): Observable<SearchResult[]> {
    const manifestations = this.manifestationService.get();
    const illnessscripts = this.illnessScriptService.getAll();

    const filteredManifestations = manifestations.pipe(
      map((manifestations) =>
        manifestations.filter((m) =>
          m.displayName.toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );

    const filteredIllnessScripts = illnessscripts.pipe(
      map((illnessScripts) =>
        illnessScripts.filter((i) =>
          i.illnessName.toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );

    return combineLatest([filteredManifestations, filteredIllnessScripts]).pipe(
      map(([manifestations, illnessScripts]) => {
        const searchResults: SearchResult[] = [];

        searchResults.push(
          ...manifestations.map((m) => ({
            id: m.id,
            type: 'Problems',
            title: m.displayName.trim(),
          }))
        );

        searchResults.push(
          ...illnessScripts.map((i) => ({
            id: i.id,
            type: 'Illness Scripts',
            title: i.illnessName.trim(),
          }))
        );

        return searchResults;
      })
    );
  }

  getRecentSearches(): RecentSearch[] {
    const searches = localStorage.getItem(this.STORAGE_KEY);
    return searches ? JSON.parse(searches) : [];
  }

  saveRecentSearch(search: string): void {
    if (!search) {
      return;
    }

    const searches: RecentSearch[] = this.getRecentSearches();
    const existingSearch = searches.find((s) => s.title === search);
    if (existingSearch) {
      return;
    }

    const newSearches: RecentSearch = {
      title: search,
    };
    searches.push(newSearches);
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(searches));
  }

  removeRecentSearch(recentSearch: string): void {
    const searches: RecentSearch[] = this.getRecentSearches();
    const newSearches = searches.filter((s) => s.title !== recentSearch);
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(newSearches));
  }

  clearRecentSearches(): void {
    localStorage.removeItem(this.STORAGE_KEY);
  }
}
