import {
  AfterContentChecked,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss'],
})
export class SubheaderComponent implements AfterContentChecked {
  private readonly MAX_HEIGHT = 24;

  @Input() title = 'Title';
  @Input() type: 'step' | 'algorithm' = 'step';
  @Input() problemStatements: string[] = [];

  @ViewChildren('breadcrumbItem') breadcrumbItems:
    | QueryList<ElementRef>
    | undefined;

  showProblemStatementHistory = false;
  forceBreadcrumbOpener = false;

  constructor(private router: Router) {}

  public get classes(): string[] {
    return ['header', `header--${this.type}`];
  }

  ngAfterContentChecked() {
    if (!this.breadcrumbItems || this.breadcrumbItems.length === 0) return;
    const currentProblemStatement = this.breadcrumbItems.last.nativeElement;
    const previousProblemStatement = this.breadcrumbItems.first.nativeElement;

    this.forceBreadcrumbOpener = false;
    ['overflown', 'hide'].forEach((className) => {
      currentProblemStatement.classList.remove(className);
      previousProblemStatement.classList.remove(className);
    });

    if (this.breadcrumbItems.length === 1) {
      if (currentProblemStatement.offsetHeight > this.MAX_HEIGHT) {
        currentProblemStatement.classList.add('overflown');
        return;
      }
    }

    if (currentProblemStatement.offsetHeight > this.MAX_HEIGHT) {
      previousProblemStatement.classList.add('overflown');

      if (currentProblemStatement.offsetHeight > this.MAX_HEIGHT) {
        previousProblemStatement.classList.add('hide');
        this.forceBreadcrumbOpener = true;

        if (currentProblemStatement.offsetHeight > this.MAX_HEIGHT) {
          currentProblemStatement.classList.add('overflown');
        }
      }
    }
  }

  openAlgorithm() {
    this.router.navigateByUrl(`${this.router.url}/algorithm`);
  }

  closeAlgorithm() {
    this.router.navigateByUrl(this.router.url.replace('/algorithm', ''));
  }

  get visibleProblemStatements() {
    return this.problemStatements.slice(-2);
  }

  redirectToHomePage() {
    this.router.navigateByUrl(`/${this.router.url.split('/')[1]}`);
    return false;
  }
}
