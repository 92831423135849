<div class="step-cta-wrapper">
    <div class="step-cta" [ngClass]="{'step-cta-differential': type === 'differential', 'step-cta-review': type === 'review'}">
    <ng-container *ngIf="type === 'question'">
        <ng-container *ngIf="options !== null && options.length > 0">
          <ng-container *ngIf="options.length === 1">
              <app-button [type]="'secondary'" (click)="onClick(0)" [label]='options[0].label' />
            </ng-container>
            <ng-container *ngIf="options.length >= 2">
              <app-radio-group [options]="options" />
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="type === 'differential'">
        <ng-container *ngIf="options !== null && options.length > 0">
            <ng-container *ngIf="options.length === 1">
              <app-button [type]="'secondary'" (click)="onClick(0)" [label]='options[0].label' />
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="type === 'review'">
        <ng-container *ngIf="options !== null && options.length > 0">
            <ng-container *ngIf="options.length === 2">
              <app-button *ngFor="let option of options; index as i" [type]="i % 2 === 0 ? 'secondary' : 'outline-blue'" (click)="onClick(i)" [label]='option.label' [id]="option.id"/>
            </ng-container>
        </ng-container>
    </ng-container>
    </div>
</div>
