import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderWrapperComponent } from './header-wrapper.component';
import { FloatingButtonComponent } from '../floating-button/floating-button.component';
import { SubheaderComponent } from '../subheader/subheader.component';
import { HeaderComponent } from './components/header/header.component';
import { ProblemStatementHistoryComponent } from './components/problem-statement-history/problem-statement-history.component';
import { LinkComponent } from '../link/link.component';
import { StoreModule } from '@ngrx/store';
import { algorithmFeatureKey } from '../../../lib/algorithm/store/algorithm.selector';
import { algorithmReducer } from '../../../lib/algorithm/store/algorithm.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AlgorithmEffects } from '../../../lib/algorithm/store/algorithm.effect';
import { AlgorithmService } from '../../../lib/algorithm/algorithm.service';
import { MenuDrawerMobileComponent } from '../menu-drawer-mobile/menu-drawer-mobile.component';
import { ModalComponent } from '../modal/modal.component';
import { ListItemComponent } from '../list-item/list-item.component';
import { searchFeatureKey } from 'src/lib/search/store/search.selector';
import { searchReducer } from 'src/lib/search/store/search.reducer';
import { SearchEffects } from 'src/lib/search/store/search.effect';
import { FormsModule } from '@angular/forms';
import { FeatureFlagsService } from 'src/lib/feature-flags/feature-flags.service';
import { SearchModule } from '../search/search.module';
import { SvgComponent } from '../svg/svg.component';

@NgModule({
  declarations: [
    HeaderWrapperComponent,
    HeaderComponent,
    SubheaderComponent,
    ProblemStatementHistoryComponent,
  ],
  providers: [AlgorithmService, FeatureFlagsService],
  exports: [HeaderWrapperComponent],
  imports: [
    CommonModule,
    FloatingButtonComponent,
    LinkComponent,
    StoreModule.forFeature(algorithmFeatureKey, algorithmReducer),
    EffectsModule.forFeature([AlgorithmEffects]),
    StoreModule.forFeature(searchFeatureKey, searchReducer),
    EffectsModule.forFeature([SearchEffects]),
    MenuDrawerMobileComponent,
    ModalComponent,
    ListItemComponent,
    FormsModule,
    SearchModule,
    SvgComponent,
  ],
})
export class HeaderWrapperModule {}
