import { Injectable } from '@angular/core';
import { Observable, toArray } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IllnessScriptModel } from './illness-script';
import { from, map, mergeMap } from 'rxjs';
import { IllnessScriptNode } from '../mock-integration/hierarchy.model';
import { DataFetchService } from '../mock-integration/data-fetch.service';
import { bundleTree } from '../mock-integration/bundle.tree';

@Injectable()
export class IllnessScriptService {
  constructor(
    private httpClient: HttpClient,
    private dataFetchService: DataFetchService
  ) {}

  getAll(): Observable<IllnessScriptModel[]> {
    const data = this.httpClient.get<IllnessScriptModel[]>(
      environment.apiBaseUrl + '/illness-scripts/'
    );

    const dataFromFiles = this.getAllFromFiles();

    return data.pipe(
      mergeMap((apiData) =>
        dataFromFiles.pipe(
          map((fileData) => {
            fileData = fileData.filter((item) => item.id !== undefined);
            apiData = apiData.filter((item) => item.id !== undefined);

            const combinedData = [...apiData, ...fileData];
            const uniqueData = combinedData.filter(
              (item, index, self) =>
                index ===
                self.findIndex((t) => t.illnessName === item.illnessName)
            );
            return uniqueData;
          })
        )
      )
    );
  }

  get(ids: string[]): Observable<IllnessScriptModel[]> {
    const data = this.httpClient.get<IllnessScriptModel[]>(
      environment.apiBaseUrl + '/illness-scripts/',
      {
        params: {
          id: ids,
        },
      }
    );

    return data.pipe(
      mergeMap((apiData) => {
        if (apiData.length > 0) {
          return from([apiData]);
        } else {
          return this.getFromFileByIds(ids);
        }
      })
    );
  }

  getAllFromFiles(): Observable<IllnessScriptModel[]> {
    return from(bundleTree.data).pipe(
      mergeMap((data) => {
        return from(data.illness_script).pipe(
          mergeMap((file) =>
            this.dataFetchService
              .getJsonFile(
                `${data.id}/en_us/1/illness_script/${file.file_name}`
              )
              .pipe(map((json) => this.mapJsonToIllnessScriptModel(json)))
          ),
          toArray()
        );
      }),
      toArray(),
      map((arrays) => arrays.flat())
    );
  }

  getFromFileByIds(ids: string[]): Observable<IllnessScriptModel[]> {
    return from(bundleTree.data).pipe(
      mergeMap((data) => {
        return from(data.illness_script).pipe(
          mergeMap((file) =>
            this.dataFetchService
              .getJsonFile(
                `${data.id}/en_us/1/illness_script/${file.file_name}`
              )
              .pipe(map((json) => this.mapJsonToIllnessScriptModel(json)))
          ),
          toArray()
        );
      }),
      toArray(),
      map((arrays) => arrays.flat()),
      map((illnessScripts) =>
        illnessScripts.filter((illnessScript) => ids.includes(illnessScript.id))
      )
    );
  }

  private mapJsonToIllnessScriptModel(json: any): IllnessScriptModel {
    const illnessScriptNone = json as IllnessScriptNode;
    const contentNodes = illnessScriptNone.content;

    return {
      id: illnessScriptNone.id,
      name: illnessScriptNone.slug,
      illnessName: illnessScriptNone.title,
      emergent: this.dataFetchService.mapContentNodesToBoolean(
        contentNodes,
        'diagnoses_type',
        'Emergent'
      ),
      must_not_miss: this.dataFetchService.mapContentNodesToBoolean(
        contentNodes,
        'diagnoses_type',
        'Must not miss'
      ),
      overview: this.dataFetchService.mapContentNodesToString(
        contentNodes,
        'summary'
      ),
      treatment: [],
      epidemiology: this.dataFetchService.mapContentNodesToString(
        contentNodes,
        'etiology_risk_factors'
      ),
      pearlsAndPitfalls: this.dataFetchService.mapContentNodesToString(
        contentNodes,
        'pearls_and_pitfalls'
      ),
      timeCourse: this.dataFetchService.mapContentNodesToString(
        contentNodes,
        'time_course'
      ),
      diagnostics: [],
      pathophysiology: this.dataFetchService.mapContentNodesToString(
        contentNodes,
        'pathophysiology'
      ),
      salientFeatures: [],
      citations: [],
      ruledOut: undefined,
      images: undefined,
    };
  }
}
