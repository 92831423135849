import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  IllnessScriptListState,
  SelectedIllnessScriptsState,
} from './illness-script.reducer';

export const illnessScriptFeatureKey = 'illnessScript';
export const selectedIllnessScriptFeatureKey = 'selectedIllnessScript';

export const selectIllnessScriptState =
  createFeatureSelector<IllnessScriptListState>(illnessScriptFeatureKey);

export const selectSelectedIllnessScriptState =
  createFeatureSelector<SelectedIllnessScriptsState>(
    selectedIllnessScriptFeatureKey
  );

export const selectAllIllnessScripts = () =>
  createSelector(selectIllnessScriptState, (state) =>
    Object.values(state.entities)
  );

export const selectIllnessScript = (id: string) =>
  createSelector(selectIllnessScriptState, (state) => state.entities[id]);

export const selectIllnessScripts = (ids: string[]) =>
  createSelector(selectIllnessScriptState, (state) =>
    Object.values(state.entities).filter((illness) => ids.includes(illness.id))
  );

export const selectSelectedIllnessScripts = () =>
  createSelector(
    selectSelectedIllnessScriptState,
    (state) => state.selectedIllnesses
  );

export const selectCompareModalIsOpen = () =>
  createSelector(
    selectSelectedIllnessScriptState,
    (state) => state.compareModalIsOpen
  );
