<div class="modal-backdrop" (click)="closeModal()"></div>
<div (keydown)="onKeyDown($event)" [style.left]="currentLeft" class="modal d-block {{transitionClass}}">
  <div
    #modal 
    [style.width]="currentWidth"
    [style.maxWidth]="size === 'normal' ? '600px' : '845px'"
    class="modal-dialog modal-fullscreen modal-dialog-scrollable modal-size"
  >
  <div tabindex="1" class="modal-content">
    <div 
    class="modal-header" 
    [style.padding]="headerPadding" 
    [ngClass]="{'modal-header-small': headerStyle === 'small'}"
    >
      <h1 *ngIf="headerText && headerText !==''" class="modal-header-text mx-3" [ngClass]="{'modal-header-text-small': headerStyle === 'small'}">{{headerText}}</h1>
      <button tabindex="1" id='close-btn' type='button' #closeBtn aria-label='Close' [ngClass]='classes'
      (click)='closeModal()'><img alt='close icon' [src]='icon' /></button>
    </div>
    <div class="modal-body" [style.padding]="padding" [style.paddingTop]="'0'">
        <h1 *ngIf='title' class="modal-title">
          <img *ngIf='titleIcon' [src]="titleIcon" alt='modal title icon' />
          {{title}}
        </h1>
        <ng-content></ng-content>
      </div>
      <div class="modal-footer"><ng-content select="[footer]" [ngTemplateOutlet]="content.templateRef"></ng-content>
      </div>
    </div>
  </div>
</div>