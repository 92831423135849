// working-ddx.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as WorkingDDxActions from './working-ddx.actions';
import { WorkingDDxService } from '../working-ddx.service';
@Injectable()
export class WorkingDDxEffects {
  constructor(
    private actions$: Actions,
    private workingDdxService: WorkingDDxService
  ) {}

  loadWorkingDDx$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkingDDxActions.getWorkingDDxfromStep),
      mergeMap((action) =>
        this.workingDdxService
          .fetchAndMapData(action.manifestationId, action.stepId, action.ddxId)
          .pipe(
            map((workingDDx) =>
              WorkingDDxActions.getWorkingDDxSuccess({ workingDDx })
            ),
            catchError((error) =>
              of(WorkingDDxActions.getWorkingDDxFailure({ error }))
            )
          )
      )
    )
  );
}
