import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { DataFetchService } from '../mock-integration/data-fetch.service';
import { ContentNode } from '../mock-integration/hierarchy.model';
import { WorkingDDxModel } from './working-ddx.model';

@Injectable({
  providedIn: 'root',
})
export class WorkingDDxService {
  constructor(private dataFetchService: DataFetchService) {}

  fetchAndMapData(
    manifestationId: string,
    stepId: string,
    ddxId: string
  ): Observable<WorkingDDxModel[]> {
    const basePath = `${manifestationId}/en_us/1/`;
    return this.dataFetchService.getHierarchy(manifestationId).pipe(
      map((hierarchy) => this.dataFetchService.findStepNode(hierarchy, stepId)),
      switchMap((stepNode) => {
        if (!stepNode) {
          throw new Error(`Step node with id ${stepId} not found`);
        }
        const workingDdxNode = stepNode.children.find(
          (child) => child.id === ddxId && child.template === 'working_ddx'
        );

        if (!workingDdxNode) {
          throw new Error(`Working DDX node with id ${ddxId} not found`);
        }
        const illnessScriptNodes = workingDdxNode.children.filter(
          (child) => child.template === 'illness_script'
        );

        const illnessScriptObservables = illnessScriptNodes.flatMap((node) =>
          node.children
            .filter((child) => child.template === 'illness_script')
            .map((childNode) =>
              this.dataFetchService
                .getJsonFile<any>(
                  `${basePath}illness_script/${childNode.slug}.json`
                )
                .pipe(
                  map((illnessScript) => {
                    return this.mapToWorkingDDxModel(
                      childNode.id,
                      illnessScript
                    );
                  })
                )
            )
        );
        const nestedIllnessScriptObservables = illnessScriptNodes.flatMap(
          (node) =>
            node.children
              .filter(
                (child) =>
                  child.template === 'illness_script' &&
                  child.children.length > 0
              )
              .flatMap((childNode) =>
                childNode.children
                  .filter(
                    (grandChild) =>
                      grandChild.template === 'illness_script' &&
                      grandChild.children.length === 0
                  )
                  .map((grandChildNode) =>
                    this.dataFetchService
                      .getJsonFile<any>(
                        `${basePath}illness_script/${grandChildNode.slug}.json`
                      )
                      .pipe(
                        map((illnessScript) => {
                          return this.mapToWorkingDDxModel(
                            grandChildNode.id,
                            illnessScript
                          );
                        })
                      )
                  )
              )
        );

        return forkJoin([
          ...illnessScriptObservables,
          ...nestedIllnessScriptObservables,
        ]);
      })
    );
  }

  private mapToWorkingDDxModel(
    workingDdxId: string,
    illnessScript: any
  ): WorkingDDxModel {
    const contentNodes: ContentNode[] = illnessScript.content;
    return {
      id: workingDdxId,
      illnessId: illnessScript.id,
      illnessName: illnessScript.title,
      riskFactors: this.dataFetchService.mapContentNodesToString(
        contentNodes,
        'etiology_risk_factors'
      ),
      symptoms: this.dataFetchService.mapContentNodesToString(
        contentNodes,
        'symptoms_all'
      ),
      signs: this.dataFetchService.mapContentNodesToString(
        contentNodes,
        'signs_all'
      ),
      emergent: this.dataFetchService.mapContentNodesToBoolean(
        contentNodes,
        'diagnoses_type',
        'Emergent'
      ),
      must_not_miss: this.dataFetchService.mapContentNodesToBoolean(
        contentNodes,
        'diagnoses_type',
        'Must not miss'
      ),
      workupContent: this.dataFetchService.mapContentNodesToString(
        contentNodes,
        'work_up'
      ),
    };
  }
}
