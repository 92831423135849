<div class="images-wrapper" [class.imageWrapperGrid]="mode === 'grid'">
  <div class="image-frame" *ngFor='let image of images'>
    <div (keydown)="onKeyDown($event, image)" tabindex="0" role="img" class="image" [style.backgroundImage]="'url('+image.url+')'" data-testId='main-carousel-image'
      [ariaLabel]='image.altText'></div>
    <img src='assets/zoom-in.svg' alt='zoom in' class='zoom-in' (load)="onImageLoad()" (click)='openDetail(image)'>
  </div>
</div>
<app-modal title='' [icon]="'assets/close.svg'" (close)='closeDetail()' *ngIf='showDetail'>
  <swiper-container init='false' id='detail-swiper' #swiperDetailRef>
    <swiper-slide *ngFor='let image of images'>
      <div class='swiper-zoom-container'>
        <img [src]='image.url' [alt]='image.altText' data-testId='detail-carousel-image' />
      </div>
    </swiper-slide>
  </swiper-container>

  <div class='d-flex justify-content-center'>
    <div class='toolbar'>
      <div class='navigation' (click)='previous()'>
        <img src='assets/previous.svg' alt='previous'>
      </div>

      <span>{{detailIndex}} of {{images.length}}</span>

      <div class='navigation' (click)='next()'>
        <img src='assets/next.svg' alt='next'>
      </div>
    </div>
  </div>
</app-modal>