import { Component, OnInit } from '@angular/core';
import {
  arrowIconChips,
  DiagnosesIconChips,
  emergentIconChips,
  emergentWithLabelIconChips,
  mustNotMissIconChips,
  mustNotMissWithLabelIconChips,
} from '../icon-chips/icon-chips.constants';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Input } from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  Observable,
  Subject,
  takeUntil,
} from 'rxjs';
import { UnsubscribeOnDestroyAdapter } from 'src/lib/base/unsubscribe.adapter';
import { IllnessScriptModel } from 'src/lib/illness-script/illness-script';
import { selectIllnessScript } from 'src/lib/illness-script/store/illness-script.selector';
import { getWorkingDDxfromStep } from 'src/lib/working-ddx/store/working-ddx.actions';
import { WorkingDDxState } from 'src/lib/working-ddx/store/working-ddx.reducer';
import { selectWorkingDDx } from 'src/lib/working-ddx/store/working-ddx.selectors';
import { WorkingDDxModel } from 'src/lib/working-ddx/working-ddx.model';
import { getIllnessScript } from 'src/lib/illness-script/store/illness-script.actions';
import { Filter } from 'src/lib/search/search';

@Component({
  selector: 'app-working-ddx-table',
  templateUrl: './working-ddx-table.component.html',
  styleUrls: ['./working-ddx-table.component.scss'],
})
export class WorkingDDxTableComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  constructor(
    private readonly store: Store<{ workingDDx: WorkingDDxState }>,
    protected sanitizer: DomSanitizer
  ) {
    super();
  }
  @Input() manifestationId!: string;
  @Input() stepId!: string;
  @Input() ddxId!: string;

  WorkingDDx$!: Observable<WorkingDDxModel[]>;
  workingDdxList!: WorkingDDxModel[];
  visibleIllness: IllnessScriptModel | null = null;

  private destroyed$ = new Subject<void>();

  iconChips = DiagnosesIconChips;
  emergentIcon = emergentIconChips;
  mustNotMissIcon = mustNotMissIconChips;
  emergentWitLabelIcon = emergentWithLabelIconChips;
  mustNotMissWitLabelIcon = mustNotMissWithLabelIconChips;
  arrowIcon = arrowIconChips;

  showModal = false;
  workupData = {
    illnessName: '',
    emergent: false,
    mustNotMiss: false,
    info: '',
  };

  readonly filters: Filter[] = [
    { name: 'Risk factors', value: 'risks', checked: true },
    { name: 'Associated symptoms', value: 'symptoms', checked: true },
    { name: 'Signs', value: 'signs', checked: true },
  ];
  readonly filtersSubject = new BehaviorSubject(this.filters);
  readonly filters$ = this.filtersSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  ngOnInit(): void {
    this.loadDdx();
  }

  ngAfterViewInit(): void {
    this.loadDdx();
  }

  override ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    super.ngOnDestroy();
  }

  loadDdx = () => {
    this.store.dispatch(
      getWorkingDDxfromStep({
        manifestationId: this.manifestationId,
        stepId: this.stepId,
        ddxId: this.ddxId,
      })
    );

    this.WorkingDDx$ = this.store.select(selectWorkingDDx);

    this.WorkingDDx$.subscribe((data) => {
      this.workingDdxList = [...data].sort((a, b) =>
        a.illnessName.localeCompare(b.illnessName)
      );
    });
  };

  toSafeHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  openIllnessScript = (id: string) => {
    this.store.dispatch(getIllnessScript({ ids: [id] }));

    this.store
      .select(selectIllnessScript(id))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((illness) => {
        this.visibleIllness = illness;
      });
  };

  openWorkup = (id: string) => {
    const currentDdx = this.workingDdxList.find((x) => x.id === id);

    if (currentDdx) {
      this.showModal = true;
      this.setWorkupData(currentDdx);
    }
  };

  setWorkupData = (currentDdx: WorkingDDxModel) => {
    this.workupData.illnessName = currentDdx.illnessName;
    this.workupData.emergent = currentDdx.emergent;
    this.workupData.mustNotMiss = currentDdx.must_not_miss;
    this.workupData.info = currentDdx.workupContent
      ? currentDdx.workupContent
      : 'No content available';
  };

  shouldMergeRow(row: WorkingDDxModel): boolean {
    return !row.riskFactors && !row.symptoms && !row.signs;
  }

  onFilterChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    const filterValue = target.value;
    const isChecked = target.checked;

    this.filtersSubject.next(
      this.filtersSubject
        .getValue()
        .map((filter) =>
          filter.value === filterValue
            ? { ...filter, checked: isChecked }
            : filter
        )
    );
  }

  showSection(filter: string): boolean {
    return (
      this.filtersSubject.getValue().find((f) => f.value === filter)?.checked ??
      false
    );
  }
}
