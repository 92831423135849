import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import ButtonComponent from '../button/button.component';
import { UnsubscribeOnDestroyAdapter } from '../../../lib/base/unsubscribe.adapter';
import { timer } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-drawer-mobile',
  templateUrl: './menu-drawer-mobile.component.html',
  standalone: true,
  styleUrls: ['./menu-drawer-mobile.component.scss'],
  imports: [ButtonComponent, CommonModule],
})
export class MenuDrawerMobileComponent
  extends UnsubscribeOnDestroyAdapter
  implements AfterViewInit
{
  @ViewChild('closeBtn') closeBtn: ElementRef | undefined;

  @Input() title = '';
  @Input() position: 'left' | 'right' = 'right';
  @Input() icon?: string;
  @Input() titleIcon?: string;

  @Output() close = new EventEmitter<boolean>();

  constructor(private cdr: ChangeDetectorRef, private router: Router) {
    super();
  }

  transitionClass: 'hide' | 'show' = 'hide';
  openDropdown = false;

  closeModal() {
    this.closeModalAnimation();
    this.subs.add(
      timer(500).subscribe(() => {
        this.close.emit();
      })
    );

    return false;
  }

  closeModalAnimation() {
    this.transitionClass = 'hide';
  }

  ngAfterViewInit(): void {
    this.subs.add(
      timer(5).subscribe(() => {
        this.closeBtn?.nativeElement.focus();
        this.transitionClass = 'show';
      })
    );

    this.cdr.detectChanges();
  }

  public get classes(): string[] {
    return [`btn--${this.position}`];
  }

  redirectToHomePage() {
    this.router.navigate(['/']);
  }

  openAccountDetails() {
    this.router.navigateByUrl(`/account?redirectUrl=${window.location.href}`);
  }

  signOut() {
    this.router.navigateByUrl(`/mg/auth?redirectUrl=${window.location.href}`);
  }
}
