import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { filter } from 'rxjs';
import { UnsubscribeOnDestroyAdapter } from 'src/lib/base/unsubscribe.adapter';
import { ScrollService } from '../lib/base/scroll.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  constructor(
    private router: Router,
    private swUpdate: SwUpdate,
    private scrollService: ScrollService
  ) {
    super();
  }

  applyStyle = false;
  gradient = false;

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate();
    }
    this.router.events
      .pipe(filter((e) => e instanceof NavigationStart))
      .subscribe((e) => {
        if (e instanceof NavigationStart) {
          this.applyStyle =
            e.url.includes('step') || e.url.includes('differential');
          this.gradient = e.url === '/';

          this.scrollService.scrollContentToTop();
        }
      });
  }
}
