import { Component, Input, OnInit } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
  standalone: true,
  imports: [CommonModule, HttpClientModule],
})
export class SvgComponent implements OnInit {
  @Input()
  svg!: string;
  @Input() fill? = '#000000';
  public svgIcon$?: Observable<SafeHtml>;

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.loadSvgIcon();
  }

  private loadSvgIcon(): void {
    this.svgIcon$ = this.httpClient
      .get(`assets/${this.svg}.svg`, { responseType: 'text' })
      .pipe(
        map((svgData) => this.processSvg(svgData)),
        catchError((error) => {
          console.error('Error loading SVG:', error);
          return of('');
        })
      );
  }

  private processSvg(svgData: string): SafeHtml {
    const modifiedSvg = this.changeFill(svgData, this.fill || '#000000');
    return this.sanitizer.bypassSecurityTrustHtml(modifiedSvg);
  }

  private changeFill(svgData: string, fill: string): string {
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgData, 'image/svg+xml');
    const svgElement = svgDoc.querySelector('svg');

    if (svgElement) {
      this.updateSvgAttributes(svgElement, fill);
    }

    return new XMLSerializer().serializeToString(svgDoc);
  }

  private updateSvgAttributes(svgElement: SVGElement, fill: string): void {
    const elementsWithFill = svgElement.querySelectorAll('[fill]');
    elementsWithFill.forEach((el) => el.setAttribute('fill', fill));

    const elementsWithStroke = svgElement.querySelectorAll('[stroke]');
    elementsWithStroke.forEach((el) => el.setAttribute('stroke', fill));
  }
}
