<ng-container class="search-wrapper">
    <app-search-input class="input-container" (clearEvent)="clearSearch()" (onSearchTextChange)="onSearchTextChange($event)"
        (onSubmitSearch)="onSearch($event)" [placeholder]="'Search problems and illness scripts'"
        [searchText]="(searchText$ | async) ?? ''" [autoFocus]="true" />

    <div *ngIf="(showRecentSearches$ | async)" class="recent-search">
        <div class="recent-search-info">
            <p class="subtitle">Recent searches</p>
            <app-list-item *ngFor='let search of (filteredRecentSearches$ | async)' [title]='search.title'
                (onClick)='submitSearch(search.title)' [icon]="'/assets/search-dark.svg'"
                [iconCTA]="'/assets/close.svg'" [iconCTALabel]="'Remove search'"
                (onIconCTAClick)='removeRecentSearch(search.title)' />
        </div>
        <div class="clear-recent-search">
            <app-link class="text-center" (click)='clearSearchHistory()' [label]="'Clear search history'" />
        </div>
    </div>
    <div *ngIf="(showSearchResults$ | async)" class="search-results">
        <p *ngIf="searchResultTotal > 0" class="subtitle">{{searchResultTotal}} results for <span
                class="fw-bold">{{(searchText$ | async) ??
                ''}}</span></p>
        <p *ngIf="searchResultTotal === 0" class="subtitle">No results found for <span class="fw-bold">{{(searchText$ |
                async) ?? ''}}</span></p>
        <div class="row">
            <div class="col-12 d-flex flex-wrap">
                <div class="d-flex flex-grow-1 pb-2">
                    <app-checkbox-button class="me-2 mb-2 col-6 col-md-auto" *ngFor="let filter of filters; "
                        [checked]="filter.checked" [label]="filter.name" [value]="filter.value"
                        [count]="getCountForType(filter.name)" (onChangeCheckbox)="onFilterChange($event)" />
                </div>
                <div class="w-100 d-block d-md-none"></div>
                <app-select class="select-end custom-width mb-2 ms-auto" [id]="'sort-select'" label="Sort By"
                    [options]="sortOptions" [selectedOption]="selectedSortOption"
                    (onChangeSelect)="onSortChange($event)" />
            </div>
        </div>

        <div *ngFor='let group of ((filteredSearchResults$ | async) ?? []); trackBy: trackByKey'>
            <div *ngIf="group.value.length > 0 || getCountForType(group.key) == 0" class="search-results-group">
                <p class="group-title">{{group.key}}
                    <span>({{getCountForType(group.key)}})</span>
                </p>
                <ng-container *ngIf="getCountForType(group.key)> 0; else noResults">
                    <app-list-item *ngFor='let result of group.value; trackBy: trackById' [title]='result.title'
                        (onClick)='openResult(result.id, result.type)' [icon]="'/assets/search-dark.svg'" />
                    <div *ngIf="group.value.length > 0" class="load-more-search">
                        <app-link class="text-center" *ngIf="hasMoreToLoad(group.key, getCountForType(group.key))"
                            (click)="loadMore(group.key)" [label]="'Load More'" />
                    </div>
                </ng-container>
                <ng-template #noResults>
                    <p class="subtitle">No results found</p>
                </ng-template>
            </div>
        </div>
    </div>
</ng-container>
<app-single-illness *ngIf='visibleIllness' (onClose)='visibleIllness = null'
    [illness]="visibleIllness"></app-single-illness>