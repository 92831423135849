export const illnessTypes = [
  {
    title: 'Pathophysiology',
    type: 'pathophysiology',
  },
  {
    title: 'Epidemiology',
    type: 'epidemiology',
  },
  {
    title: 'Time Course',
    type: 'timeCourse',
  },
  {
    title: 'Salient Symptoms & Signs',
    type: 'salientFeatures',
  },
  {
    title: 'Diagnostics',
    type: 'diagnostics',
  },
  {
    title: 'Treatment',
    type: 'treatment',
  },
];