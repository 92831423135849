import { createReducer, on } from '@ngrx/store';
import {
  getAllIllnessScriptsSuccess,
  getIllnessScriptSuccess,
  resetSelectedIllnessScript,
  setCompareModalIsOpen,
  setSelectedIllnessScript,
} from './illness-script.actions';
import { IllnessScriptModel } from '../illness-script';

export interface IllnessScriptListState {
  entities: { [id: string]: IllnessScriptModel };
}

export const initialState: IllnessScriptListState = {
  entities: {},
};

export const illnessScriptListReducer = createReducer(
  initialState,
  on(getAllIllnessScriptsSuccess, (state, { data }) => ({
    ...state,
    entities: data.reduce(
      (acc, illnessScript) => ({
        ...acc,
        [illnessScript.id]: illnessScript,
      }),
      state.entities
    ),
  })),
  on(getIllnessScriptSuccess, (state, { data }) => ({
    ...state,
    entities: data.reduce(
      (acc, illnessScript) => ({
        ...acc,
        [illnessScript.id]: illnessScript,
      }),
      state.entities
    ),
  }))
);

export interface SelectedIllnessScriptsState {
  selectedIllnesses: IllnessScriptModel[];
  compareModalIsOpen: boolean;
}

export const selectedIllnessInitialState: SelectedIllnessScriptsState = {
  selectedIllnesses: [],
  compareModalIsOpen: false,
};

export const selectedIllnessScriptReducer = createReducer(
  selectedIllnessInitialState,
  on(setSelectedIllnessScript, (state, { data, checked }) => ({
    ...state,
    selectedIllnesses: checked
      ? [...state.selectedIllnesses, data]
      : state.selectedIllnesses.filter((x) => x.id !== data.id),
  })),
  on(setCompareModalIsOpen, (state, { isOpen }) => ({
    ...state,
    compareModalIsOpen: isOpen,
  })),
  on(resetSelectedIllnessScript, (state) => selectedIllnessInitialState)
);
