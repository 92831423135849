// hierarchy.model.ts
export interface HierarchyNode {
  id: string;
  version: number;
  title: string;
  slug: string;
  template: string;
  children: HierarchyNode[];
}

export interface ContentNode {
  fieldLabel: string;
  fieldName: string;
  fieldType: string;
  multiValued: boolean;
  data: string | string[] | ContentNodeData;
}

export interface ContentNodeData {
  id: string;
  bankId: string;
  assetId: string;
  name: string;
  description: string;
  caption: string;
  locale: string;
  alternativeText: string;
  extension: string;
}

export interface IllnessScriptNode {
  id: string;
  version: number;
  title: string;
  slug: string;
  description: string;
  publishedAt: string;
  template: string;
  locale: string;
  collectionId: string;
  content: ContentNode[];
}

export enum NodeTemplate {
  HOME = 'home',
  ILLNESS_SCRIPT = 'illness_script',
  STEP = 'step',
  WORKING_DDX = 'working_ddx',
}

export enum ContentNodeType {
  DOCUMENT = 'document',
  ENUM = 'enum',
  IMAGE = 'image',
  QUIZ = 'quiz',
  RICHTEXT = 'richtext',
  PLAINTEXT = 'plaintext',
}
