import { createAction, props } from '@ngrx/store';
import { WorkingDDxModel } from '../working-ddx.model';

export const getWorkingDDxfromStep = createAction(
  '[WorkingDDx] Get Working DDx from Step',
  props<{ manifestationId: string; stepId: string; ddxId: string }>()
);

export const getWorkingDDxSuccess = createAction(
  '[WorkingDDx] Get Working DDx Success',
  props<{ workingDDx: WorkingDDxModel[] }>()
);

export const getWorkingDDxFailure = createAction(
  '[WorkingDDx] Get Working DDx Failure',
  props<{ error: any }>()
);
