import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthService {
  private readonly MH_TOKEN = 'MH_TOKEN';

  constructor(private readonly cookieService: CookieService) {}

  isAuthenticated(): boolean {
    const token = this.cookieService.get(this.MH_TOKEN);
    return token !== undefined && token !== null && token !== '';
  }
}
