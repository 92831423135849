import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RedirectComponent } from './redirect/redirect.component';

const routes: Routes = [
  { path: 'login', component: RedirectComponent },
  { path: 'registration', component: RedirectComponent },
  {
    path: 'account',
    children: [
      {
        path: '**',
        component: RedirectComponent,
      },
    ],
  },
  {
    path: 'mg',
    children: [
      {
        path: '**',
        component: RedirectComponent,
      },
    ],
  },
  {
    path: '',
    loadChildren: () =>
      import('./authenticated-wrapper/authenticated-wrapper.module').then(
        (m) => m.AuthenticatedWrapperModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
