import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SearchState } from './search.reducer';

export const searchFeatureKey = 'search';

export const selectSearchState =
  createFeatureSelector<SearchState>(searchFeatureKey);

export const selectSearchText = createSelector(
  selectSearchState,
  (state: SearchState) => state.searchText
);

export const selectSearchResults = createSelector(
  selectSearchState,
  (state: SearchState) => state.searchResults
);

export const selectRecentSearches = createSelector(
  selectSearchState,
  (state: SearchState) => state.recentSearches
);

export const selectError = createSelector(
  selectSearchState,
  (state: SearchState) => state.error
);
