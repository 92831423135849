<ng-container *ngIf='illness'>
  <app-modal padding="0" size="large" (close)="closeModal()" [headerText]="'Illness Script'"
    [icon]="'../../assets/close.svg'" [headerStyle]="'small'">
    <div class="header">
      <h2 id="title">{{illness.illnessName}}</h2>
      <div class="labels">
        <img alt="icon indicating 'emergent' property for this illness script" *ngIf="illness.emergent"
          src="assets/medical.svg">
        <span *ngIf="illness.emergent" [style.color]="'#DA1921'">Emergent</span>
        <img alt="icon indicating 'must not miss' property for this illness script" *ngIf="illness.must_not_miss"
          src="assets/alert-triangle.svg">
        <span *ngIf="illness.must_not_miss" [style.color]="'#B55638'">Must Not Miss</span>
      </div>
    </div>
    <div class="textbox-wrapper">
      <app-rich-text id="overview" [content]="illness.overview" />
    </div>
    <div class="illnesses-wrapper">
      <div *ngIf="images.length > 0" class="gallery">
        <h3>Related Images</h3>
        <app-images-carousel [images]="images"></app-images-carousel>
        <div [style.marginBottom]="'1rem'"></div>
      </div>
      <app-accordion 
        *ngFor='let accordion of illnessTypes; let i = index' 
        [title]='accordion.title'
      >
        <app-rich-text [content]='getRichText(illness, accordion.type)' />
      </app-accordion>
      <app-accordion *ngIf="pearlsAndPitfalls" icon="lamp-purple" type="purple" title="Pearls & Pitfalls">
        {{pearlsAndPitfalls}}
      </app-accordion>

    </div>
  </app-modal>
</ng-container>