<div class="working-ddx-table" ariaLabel="Working Differential Diagnosis Table">
    <div class="heading">
        <div class="row">
            <div class="col-12">
                <div class="title-section">
                    <h1 class="text-left" id="working-ddx-title">WORKING DDx</h1>
                    <app-icon-chips [icons]="iconChips" ariaLabel="Illness script severity indicators">
                    </app-icon-chips>
                </div>
            </div>
            <div class="filter-wrapper">
                <h2 class="sr-only">Content Section Controls</h2>
                <div class="col-12 my-2">
                    <span class="filter-title">
                        Select or deselect to show/hide sections:
                    </span>
                </div>
                <div id="filter-instructions" class="sr-only">
                    These controls allow you to show or hide Risk factors, Associated symptoms, and Signs sections.
                </div>
                <div class="col-12 d-flex flex-wrap" role="group" aria-describedby="filter-instructions">
                    <app-checkbox-button class="me-2 mb-2" *ngFor="let filter of filters;" [checked]="filter.checked"
                        [label]="filter.name" [value]="filter.value" [checkedBackground]="'light-blue'"
                        (onChangeCheckbox)="onFilterChange($event)" />
                </div>
            </div>
        </div>
    </div>
    <div class="table-wrapper">
        <div class="mobile-card-view" role="list" aria-labelledby="working-ddx-title">
            <div class="card" *ngFor="let row of workingDdxList; let i = index" role="listitem">
                <div class="card-header">
                    <div class="cta-item">
                        <span class="cta-title w-100">
                            <button (click)="openIllnessScript(row.illnessId)" role="button"
                                [attr.aria-label]="'Open illness script for ' + row.illnessName" aria-haspopup="true"
                                tabindex="0">{{row.illnessName}}</button>
                            <app-svg (click)="openIllnessScript(row.illnessId)" aria-haspopup="true"
                                (keydown.enter)="openIllnessScript(row.illnessId)" class="icon"
                                [svg]="'chevron-right-small'" [fill]="'#083377'" role="button"
                                aria-hidden="true"></app-svg>
                        </span>

                        <div class="diagnoses-chips">
                            <app-icon-chips class="chips" *ngIf="row.emergent" [icons]="[emergentIcon]"
                                [attr.aria-label]="row.illnessName + ' is a Emergent diagnose'">
                            </app-icon-chips>
                            <app-icon-chips class="chips" *ngIf="row.must_not_miss" [icons]="[mustNotMissIcon]"
                                [attr.aria-label]="row.illnessName + ' is a Must Not Miss diagnose'">
                            </app-icon-chips>
                        </div>

                    </div>
                </div>

                <div class="card-content">
                    <div class="section" *ngIf="showSection('risks')">
                        <div class="section-title">Risk factors</div>
                        <div class="section-content">
                            <app-rich-text
                                [content]="row.riskFactors ? row.riskFactors : 'No content available'"></app-rich-text>
                        </div>
                    </div>

                    <div class="section" *ngIf="showSection('symptoms')">
                        <div class="section-title">Associated symptoms</div>
                        <div class="section-content">
                            <app-rich-text
                                [content]="row.symptoms? row.symptoms : 'No content available'"></app-rich-text>
                        </div>
                    </div>

                    <div class="section" *ngIf="showSection('signs')">
                        <div class="section-title">Signs</div>
                        <div class="section-content">
                            <app-rich-text [content]="row.signs? row.signs : 'No content available'"></app-rich-text>
                        </div>
                    </div>
                </div>

                <div class="content cta-item mt-4">
                    <span class="cta-title workup-button">
                        <button (click)="openWorkup(row.id)" role="button"
                            [attr.aria-label]="'Open workup for ' + row.illnessName" aria-haspopup="true" tabindex="0"
                            class="link">WORKUP</button>
                        <app-svg (click)="openWorkup(row.id)" aria-hidden="true" aria-haspopup="true" role="button"
                            (keydown.enter)="openWorkup(row.id)" class="icon" [svg]="'chevron-right-xsmall'"
                            [fill]="'#083377'"></app-svg>
                    </span>
                </div>
            </div>
        </div>
        <div class="table-responsive" tabindex="0">
            <table class="table table-bordered" aria-labelledby="working-ddx-title">
                <caption class="sr-only">Working Differential Diagnosis Table</caption>
                <thead>
                    <tr>
                        <th class="rounded-left" scope="col">Working DDx</th>
                        <th scope="col">Risk factors</th>
                        <th scope="col">Associated symptoms</th>
                        <th scope="col">Signs</th>
                        <th class="rounded-right" scope="col">Workup</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of workingDdxList; let i = index">
                        <th scope="row">
                            <div class="cta-item">
                                <span class="cta-title w-100">
                                    <button (click)="openIllnessScript(row.illnessId)" role="button"
                                        aria-haspopup="true"
                                        [attr.aria-label]="'Open illness script for ' + row.illnessName"
                                        tabindex="0">{{row.illnessName}}</button>
                                    <app-svg (click)="openIllnessScript(row.illnessId)" role="button"
                                        aria-haspopup="true" (keydown.enter)="openIllnessScript(row.illnessId)"
                                        class="icon" [svg]="'chevron-right-small'" [fill]="'#083377'"
                                        aria-hidden="true"></app-svg>
                                </span>

                                <div class="diagnoses-chips">
                                    <app-icon-chips class="chips" *ngIf="row.emergent" [icons]="[emergentIcon]"
                                        [attr.aria-label]="row.illnessName + ' is a Emergent diagnose'">
                                    </app-icon-chips>
                                    <app-icon-chips class="chips" *ngIf="row.must_not_miss" [icons]="[mustNotMissIcon]"
                                        [attr.aria-label]="row.illnessName + ' is a Must Not Miss diagnose'">
                                    </app-icon-chips>
                                </div>

                            </div>
                        </th>
                        <ng-container *ngIf="shouldMergeRow(row); else separateCells">
                            <td colspan="3" class="merged-cell">
                                <app-rich-text class="content" [content]="'No content available'"></app-rich-text>
                            </td>
                        </ng-container>
                        <ng-template #separateCells>
                            <td>
                                <app-rich-text class="content"
                                    [content]="row.riskFactors ? row.riskFactors : 'No content available'"></app-rich-text>
                            </td>
                            <td>
                                <app-rich-text class="content"
                                    [content]="row.symptoms? row.symptoms : 'No content available'"></app-rich-text>
                            </td>
                            <td>
                                <app-rich-text class="content"
                                    [content]="row.signs? row.signs : 'No content available'"></app-rich-text>
                            </td>
                        </ng-template>
                        <td>
                            <div class="content cta-item">
                                <span class="cta-title workup-button">
                                    <button (click)="openWorkup(row.id)" role="button" aria-haspopup="true"
                                        [attr.aria-label]="'Open workup for ' + row.illnessName" tabindex="0"
                                        class="link">WORKUP</button>
                                    <app-svg (click)="openWorkup(row.id)" (keydown.enter)="openWorkup(row.id)"
                                        aria-hidden="true" aria-haspopup="true" class="icon"
                                        [svg]="'chevron-right-xsmall'" [fill]="'#083377'" role="button"></app-svg>
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<app-single-illness *ngIf='visibleIllness' (onClose)='visibleIllness = null' [illness]="visibleIllness">
</app-single-illness>
<app-modal padding="0" size="large" class="workup-drawer" (close)='showModal = false' *ngIf='showModal'
    [headerText]="'Workup'" [icon]="'../../assets/close.svg'" [headerStyle]="'small'" aria-labelledby="modal-title">
    <div class="header">
        <h2 id="modal-title">{{workupData.illnessName}}</h2>
        <div class="labels">
            <app-icon-chips class="chips" *ngIf="workupData.emergent" [icons]="[emergentWitLabelIcon]"
                [attr.aria-label]="workupData.illnessName + ' is a Emergent diagnose'">
            </app-icon-chips>
            <app-icon-chips class="chips" *ngIf="workupData.mustNotMiss" [icons]="[mustNotMissWitLabelIcon]"
                [attr.aria-label]="workupData.illnessName + ' is a Must Not Miss diagnose'">
            </app-icon-chips>
        </div>
    </div>
    <div class="workup-wrapper">
        <app-rich-text [content]="workupData.info" />
    </div>
</app-modal>