<div class='radio-group-wrapper'>
  <div class='group-wrapper' [class.display-two-columns]="isBiggerThanThree">
    <div class='form-check form-check-inline'
         *ngFor='let option of options'
         (click)='selectedOption.value = option.id'>
      <input class='form-check-input'
             type='radio'
             autocomplete='off'
             name='inlineRadioOptions'
             id='inlineRadio-{{option.id}}'
             [(ngModel)]='selectedOption.value'
             [value]='option.id'>
      <label class='form-check-label' for='inlineRadio-{{option.id}}'>{{option.label}}</label>
    </div>
  </div>
  <app-button (click)='redirect()'
              class='button'
              [type]="!selectedOption.value ? 'disabled-secondary' : 'secondary'"
              [size]="'full-width'"
              [label]="'Continue'" />
</div>
