import { createAction, props } from '@ngrx/store';
import { IllnessScriptModel } from '../illness-script';

export const getAllIllnessScripts = createAction(
  '[Illness Script List API] Invoke Illness Script List API'
);

export const getAllIllnessScriptsSuccess = createAction(
  '[Illness Script List API] Fetch API Success',
  props<{ data: IllnessScriptModel[] }>()
);

export const getIllnessScript = createAction(
  '[Illness Script List API] Invoke Illness Script List API by Id',
  props<{ ids: string[] }>()
);

export const getIllnessScriptSuccess = createAction(
  '[Illness Script List API] Fetch API By Id Success',
  props<{ data: IllnessScriptModel[] }>()
);

export const setSelectedIllnessScript = createAction(
  '[Illness Script List] Set Selected Illness Script',
  props<{ data: IllnessScriptModel; checked: boolean }>()
);

export const setCompareModalIsOpen = createAction(
  '[Illness Script List] Set Compare Modal Is Open',
  props<{ isOpen: boolean }>()
);

export const resetSelectedIllnessScript = createAction(
  '[Illness Script List] Reset Selected Illness Script'
);
