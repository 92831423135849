import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkingDDxTableComponent } from './working-ddx-table.component';
import { IconChipsComponent } from '../icon-chips/icon-chips.component';
import { SvgComponent } from '../svg/svg.component';
import { LinkComponent } from '../link/link.component';
import { ListItemComponent } from '../list-item/list-item.component';
import { SingleIllnessComponent } from '../single-illness/single-illness.component';
import ButtonComponent from '../button/button.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { IllnessScriptService } from 'src/lib/illness-script/illness-script.service';
import { IllnessScriptListEffect } from 'src/lib/illness-script/store/illness-script.effect';
import { illnessScriptListReducer } from 'src/lib/illness-script/store/illness-script.reducer';
import { illnessScriptFeatureKey } from 'src/lib/illness-script/store/illness-script.selector';
import { workingDDxFeatureKey } from 'src/lib/working-ddx/store/working-ddx.selectors';
import { WorkingDDxService } from 'src/lib/working-ddx/working-ddx.service';
import { WorkingDDxEffects } from 'src/lib/working-ddx/store/working-ddx.effects';
import { RichTextComponent } from '../rich-text/rich-text.component';
import { workingDDxReducer } from 'src/lib/working-ddx/store/working-ddx.reducer';
import { StepCTAComponent } from '../../step/components/step-cta/step-cta.component';
import { ModalComponent } from '../modal/modal.component';
import { CheckboxButtonComponent } from '../checkbox-button/checkbox-button.component';

@NgModule({
  declarations: [WorkingDDxTableComponent],
  providers: [WorkingDDxService, IllnessScriptService],
  imports: [
    CommonModule,
    SvgComponent,
    LinkComponent,
    ListItemComponent,
    SingleIllnessComponent,
    ButtonComponent,
    IconChipsComponent,
    StoreModule.forFeature(workingDDxFeatureKey, workingDDxReducer),
    EffectsModule.forFeature([WorkingDDxEffects]),
    StoreModule.forFeature(illnessScriptFeatureKey, illnessScriptListReducer),
    EffectsModule.forFeature([IllnessScriptListEffect]),
    RichTextComponent,
    StepCTAComponent,
    ModalComponent,
    CheckboxButtonComponent,
  ],
  exports: [WorkingDDxTableComponent],
})
export class WorkingDDxTableModule {}
