import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import ButtonComponent from '../button/button.component';
import { UnsubscribeOnDestroyAdapter } from '../../../lib/base/unsubscribe.adapter';
import { timer } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  standalone: true,
  styleUrls: ['./modal.component.scss'],
  imports: [ButtonComponent, CommonModule],
})
export class ModalComponent
  extends UnsubscribeOnDestroyAdapter
  implements AfterViewInit
{
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.calcWidth();
    this.calcLeft();
  }

  @ViewChild('closeBtn')
  closeBtn: ElementRef | undefined;
  @ViewChild('modal') modal!: ElementRef;
  @Input() headerText = '';
  @Input() title = '';
  @Input() position: 'left' | 'right' = 'right';
  @Input() icon?: string;
  @Input() titleIcon?: string;
  @Input() size?: 'normal' | 'large' = 'normal';
  @Input() padding?: string = '1rem';
  @Input() normalHeaderPadding?: string = '1.5rem 2rem';
  @Input() mobileHeaderPadding?: string = '0.5rem 1rem 1rem 1rem';
  @Input() headerStyle?: 'default' | 'small' = 'default';
  @Output() close = new EventEmitter<boolean>();
  currentLeft!: string | undefined;
  currentWidth!: string | undefined;
  lgBreak: boolean | undefined = undefined;
  mobileBreak: boolean | undefined = undefined;
  initialSize?: 'normal' | 'large' = 'normal';
  animatedWidth?: string = '';
  animatedLeft?: string = '';
  Breakpoints = Breakpoints;
  currentBreakpoint = '';

  readonly breakpoint$ = this.breakpointObserver.observe([
    '(min-width: 576px)',
    '(min-width: 992px)',
  ]);

  get headerPadding() {
    return this.mobileBreak
      ? this.mobileHeaderPadding
      : this.normalHeaderPadding;
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') this.closeModal();
  }

  ngOnInit(): void {
    this.calcLeft();
    this.initialSize = this.size;
    this.breakpoint$.subscribe((data) => {
      this.mobileBreak = !data.breakpoints['(min-width: 576px)'];

      setTimeout(() => {
        this.animatedWidth = this.currentWidth;
        this.animatedLeft = '0';
      }, 50);

      if (this.mobileBreak) return;

      this.lgBreak = !data.breakpoints['(min-width: 992px)'];
      if (this.lgBreak) {
        this.size = 'large';
      } else {
        this.size = this.initialSize;
      }
    });
  }

  currentModalWidth() {
    if (this.modal) {
      return this.modal.nativeElement.clientWidth;
    }
    return 0;
  }

  get currentLeftOffset() {
    const limit = this.size === 'normal' ? 600 : 845;

    if (this.currentModalWidth() >= limit) return `${limit}px`;
    return this.animatedWidth;
  }

  calcLeft() {
    if (this.mobileBreak) {
      this.currentLeft = this.animatedLeft;
      return;
    }
    this.currentLeft = `calc(100vw - ${this.currentLeftOffset})`;
  }

  calcWidth() {
    if (this.mobileBreak) {
      this.currentWidth = '100vw';
      return;
    }
    this.currentWidth = this.size === 'normal' ? '40%' : '60%';
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver
  ) {
    super();
  }

  transitionClass: 'hide' | 'show' = 'hide';

  // ngAfterViewChecked() {
  //   this.calcLeft();
  //   this.calcWidth();
  // }

  closeModal() {
    this.closeModalAnimation();
    this.subs.add(
      timer(500).subscribe(() => {
        this.close.emit();
      })
    );

    return false;
  }

  closeModalAnimation() {
    this.transitionClass = 'hide';
  }

  ngAfterViewInit(): void {
    this.subs.add(
      timer(5).subscribe(() => {
        this.closeBtn?.nativeElement.focus();
        this.transitionClass = 'show';
      })
    );
    this.cdr.detectChanges();
  }

  public get classes(): string[] {
    return [
      `btn--${this.position}`,
      `btn--${this.position}${this.headerStyle === 'small' ? '-small' : ''}`,
    ];
  }
}
