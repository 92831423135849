import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioGroupComponent } from './radio-group.component';
import ButtonComponent from '../button/button.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [RadioGroupComponent],
  providers: [],
  imports: [CommonModule, ButtonComponent, RouterModule, ReactiveFormsModule, FormsModule],
  exports: [RadioGroupComponent],
})
export class RadioGroupModule {}
