import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SvgComponent } from '../svg/svg.component';

export interface IconChip {
  svg: string;
  color: string;
  label: string;
  description: string;
  hideLabel: boolean;
}

@Component({
  selector: 'app-icon-chips',
  standalone: true,
  imports: [CommonModule, SvgComponent],
  templateUrl: './icon-chips.component.html',
  styleUrls: ['./icon-chips.component.scss'],
})
export class IconChipsComponent {
  @Input() icons: IconChip[] = [];
  @Input() ariaLabel = 'Icon indicators';
}
