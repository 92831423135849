import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HeaderType } from './header.types';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  displayName$: Subject<string>;
  headerType$: Subject<HeaderType>;

  constructor() {
    this.displayName$ = new Subject<string>();
    this.displayName$.next('');

    this.headerType$ = new Subject<HeaderType>();
    this.headerType$.next(HeaderType.WithLogo);
  }

  setDisplayName(displayName: string) {
    this.displayName$.next(displayName);
  }

  setHeaderType(headerType: HeaderType) {
    this.headerType$.next(headerType);
  }
}
