import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-accordion',
  standalone: true,
  imports: [CommonModule, NgbAccordionModule],
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
  @Input() type: 'base' | 'red' | 'blue' | 'purple' | 'contextual' = 'base';

  @Input()
  title = 'Accordion';

  @Input()
  icon?: string;

  @Input()
  openedByDefault = false;

  public get classes(): string[] {
    return ['accordion', `accordion--${this.type}`];
  }
}
