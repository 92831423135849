import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { TextboxComponent } from "./textbox.component";

@NgModule({
  declarations: [
    TextboxComponent,
  ],
  exports: [
    TextboxComponent,
  ],
  imports: [
    CommonModule
  ]
})
export class TextboxModule { }
