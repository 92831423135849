// working-ddx.reducer.ts
import { createReducer, on } from '@ngrx/store';
import * as WorkingDDxActions from './working-ddx.actions';
import { WorkingDDxModel } from '../working-ddx.model';

export interface WorkingDDxState {
  workingDDx: WorkingDDxModel[];
  error: any;
}

export const initialState: WorkingDDxState = {
  workingDDx: [],
  error: null,
};

export const workingDDxReducer = createReducer(
  initialState,
  on(WorkingDDxActions.getWorkingDDxSuccess, (state, { workingDDx }) => ({
    ...state,
    workingDDx,
    error: null,
  })),
  on(WorkingDDxActions.getWorkingDDxFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);
