import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getAllIllnessScripts,
  getAllIllnessScriptsSuccess,
  getIllnessScript,
  getIllnessScriptSuccess,
} from './illness-script.actions';
import { map, mergeMap } from 'rxjs';
import { IllnessScriptService } from '../illness-script.service';

@Injectable()
export class IllnessScriptListEffect {
  constructor(
    private actions$: Actions,
    private illnessScriptListService: IllnessScriptService
  ) {}

  loadIllnessScripts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllIllnessScripts),
      mergeMap(() => {
        return this.illnessScriptListService
          .getAll()
          .pipe(map((data) => getAllIllnessScriptsSuccess({ data })));
      })
    )
  );

  loadIllnessScriptsByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getIllnessScript),
      mergeMap((action) => {
        return this.illnessScriptListService
          .get(action.ids)
          .pipe(map((data) => getIllnessScriptSuccess({ data })));
      })
    )
  );
}
