import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((e) => {
        if (e.status === 401) {
          this.router.navigateByUrl(
            `/mg/auth?redirectUrl=${window.location.href}`
          );
        }

        throw e;
      })
    );
  }
}
