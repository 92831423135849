import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Observable } from 'rxjs';
import { UnsubscribeOnDestroyAdapter } from 'src/lib/base/unsubscribe.adapter';
import { SEARCH_COMPONENT } from 'src/lib/feature-flags/feature-flags';
import { FeatureFlagsService } from 'src/lib/feature-flags/feature-flags.service';
import { HeaderService } from 'src/lib/header/header.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MOBILE_MAX_WIDTH_PIXELS } from 'src/app/step/step.constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  @Output() onClick = new EventEmitter<Event>();
  displayName = '';
  showModal = false;
  openDropdown = false;
  isHomePage = false;
  isSearchComponentEnabled$: Observable<boolean> | undefined;

  readonly breakpoint$ = this.breakpointObserver.observe([
    `(max-width: ${MOBILE_MAX_WIDTH_PIXELS}px)`,
  ]);
  Breakpoints = Breakpoints;
  isMobile = false;

  constructor(
    private headerService: HeaderService,
    private featureFlagsService: FeatureFlagsService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {
    super();
  }

  ngOnInit() {
    this.subs.add(
      this.headerService.displayName$.subscribe((r) => {
        this.displayName = r;
        this.changeDetector.detectChanges();
      })
    );

    this.featureFlagsService.loadFeatureFlags();
    this.isSearchComponentEnabled$ =
      this.featureFlagsService.isFeatureEnabled(SEARCH_COMPONENT);

    this.checkIfHomePage();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.checkIfHomePage();
      });

    this.breakpoint$.subscribe((data) => {
      this.isMobile = data.matches;
    });
  }

  checkIfHomePage() {
    this.isHomePage = this.router.url === '/home' || this.router.url === '/';
  }

  toggleDropdown() {
    this.openDropdown = !this.openDropdown;
  }

  redirectToHomePage() {
    this.router.navigate(['/']);
  }

  openAccountDetails() {
    this.router.navigateByUrl(`/account?redirectUrl=${window.location.href}`);
  }

  signOut() {
    this.router.navigateByUrl(`/mg/auth?redirectUrl=${window.location.href}`);
  }
}
