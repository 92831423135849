import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  private featureFlagsSubject = new BehaviorSubject<{ [key: string]: boolean }>(
    environment.featureFlags
  );
  featureFlags$: Observable<{ [key: string]: boolean }> =
    this.featureFlagsSubject.asObservable();

  loadFeatureFlags(): void {
    this.featureFlagsSubject.next(environment.featureFlags);
  }

  isFeatureEnabled(feature: string): Observable<boolean> {
    return this.featureFlags$.pipe(map((flags) => flags[feature] || false));
  }
}
