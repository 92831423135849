import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AlgorithmService } from '../algorithm.service';
import { map, mergeMap } from 'rxjs';
import { getAlgorithm, getAlgorithmSuccess } from './algorithm.actions';
import { Injectable } from '@angular/core';

@Injectable()
export class AlgorithmEffects {
  constructor(
    private actions$: Actions,
    private algorithmService: AlgorithmService
  ) {}

  loadAlgorithmByManifestationId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAlgorithm),
      mergeMap((action) => {
        return this.algorithmService.getById(action.manifestationId).pipe(
          map((data) =>
            getAlgorithmSuccess({
              manifestationId: action.manifestationId,
              data,
            })
          )
        );
      })
    )
  );
}
