import { createAction, props } from '@ngrx/store';
import { Algorithm } from '../algorithm';

export const getAlgorithm = createAction(
  '[Algorithm API] Invoke Algorithm API by manifestationId',
  props<{ manifestationId: string }>()
);

export const getAlgorithmSuccess = createAction(
  '[Algorithm API] Invoke Algorithm API by manifestationId Success',
  props<{ manifestationId: string; data: Algorithm[] }>()
);
