import { Injectable } from '@angular/core';
import { Manifestation } from './manifestation';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class ManifestationService {
  constructor(private httpClient: HttpClient) {}

  get(): Observable<Manifestation[]> {
    return this.httpClient.get<Manifestation[]>(
      environment.apiBaseUrl + '/manifestations'
    );
  }

  getById(id: string): Observable<Manifestation> {
    return this.httpClient.get<Manifestation>(
      environment.apiBaseUrl + '/manifestations/' + id
    );
  }
}
