import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { timer } from 'rxjs';
import { UnsubscribeOnDestroyAdapter } from '../../../../../lib/base/unsubscribe.adapter';

@Component({
  selector: 'app-problem-statement-history',
  templateUrl: './problem-statement-history.component.html',
  styleUrls: ['./problem-statement-history.component.scss'],
})
export class ProblemStatementHistoryComponent
  extends UnsubscribeOnDestroyAdapter
  implements AfterViewInit
{
  @ViewChild('closeBtn') closeBtn: ElementRef | undefined;

  @Input() title = '';
  @Input() problemStatements: string[] = [];

  @Output() goToManifestation = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  closeModal() {
    this.close.emit();
    return false;
  }

  ngAfterViewInit(): void {
    this.subs.add(
      timer(5).subscribe(() => {
        this.closeBtn?.nativeElement.focus();
      })
    );

    this.cdr.detectChanges();
  }
}
