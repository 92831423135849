import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UnsubscribeOnDestroyAdapter } from 'src/lib/base/unsubscribe.adapter';
import { HeaderService } from 'src/lib/header/header.service';
import { filter, Subject, Subscription } from 'rxjs';
import { HeaderType } from '../../../lib/header/header.types';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectProblemStatements } from '../../../lib/algorithm/store/algorithm.selector';

@Component({
  selector: 'app-header-wrapper',
  templateUrl: './header-wrapper.component.html',
  styleUrls: ['./header-wrapper.component.scss'],
})
export class HeaderWrapperComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  constructor(
    private headerService: HeaderService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private store: Store
  ) {
    super();
  }

  subheaderType: 'default' | 'algorithm' | 'step' = 'default';
  eHeaderType = HeaderType;
  headerType$: Subject<HeaderType> | undefined;
  displayName$: Subject<string> | undefined;

  problemStatement$: Subscription | undefined;
  problemStatements: string[] = [];

  getIconPath(iconName: string): string {
    const suffix = this.subheaderType === 'default' ? '-dark' : '';

    return `../../assets/${iconName}${suffix}.svg`;
  }

  ngOnInit() {
    this.displayName$ = this.headerService.displayName$;
    this.headerType$ = this.headerService.headerType$;

    this.subs.add(
      this.router.events
        .pipe(filter((e) => e instanceof NavigationEnd))
        .subscribe((e) => {
          if (e instanceof NavigationEnd) {
            this.setSubheaderType(e.url);
            this.loadProblemStatements(e.url);
            this.changeDetector.detectChanges();
          }
        })
    );
  }

  private setSubheaderType(url: string) {
    this.subheaderType = 'default';

    if (url.includes('algorithm')) {
      this.subheaderType = 'algorithm';
      return;
    }

    if (url.includes('step') || url.includes('differential')) {
      this.subheaderType = 'step';
      return;
    }
  }

  private loadProblemStatements(url: string) {
    this.problemStatement$?.unsubscribe();

    const urlParts = url.split('/').filter((s) => s.length > 0);
    const manifestationId = urlParts[0];
    const nodeId = urlParts[2] || manifestationId;

    this.problemStatement$ = this.store
      .select(selectProblemStatements(manifestationId, nodeId))
      .subscribe((r) => (this.problemStatements = r));
  }
}
