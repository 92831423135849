import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Options } from '../filter-generic/filter-buttons/filter-buttons.types';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  @Input() id = 'select';
  @Input() label = 'select';
  @Input() options: Options[] = [];
  @Input() selectedOption: Options = { label: '', value: '-', disabled: false };
  @Output() onChangeSelect = new EventEmitter<Event>();

  onChange(event: Event) {
    this.onChangeSelect.emit(event);
  }
}
