<button tabindex="-1" #button type="button" class="option-button" (click)="onButtonClick($event)"
  [attr.aria-label]="title + ' - Click to view details'">
  <span [tabindex]="setTabindex" class='list-item' (keydown)="onKeyDown($event)">
    <div class="title">
      <p> {{title}} </p>
      <img *ngIf="icon" class="item-icon" src={{icon}} [alt]='altText'>
    </div>
    <span class="icons">
      <ng-content></ng-content>
      <img class="icon-cta" src={{iconCTA}} (click)="onIconClick($event)" alt={{iconCTALabel}}>
    </span>
  </span>
</button>