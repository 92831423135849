<div *ngIf="!Mobile" class="search-container d-flex align-items-center">
    <button class="search-button d-flex align-items-center w-100" (click)="openModal()" aria-label="Open search"
        aria-haspopup="true">
        <span class="search-placeholder">Search problems and illness scripts</span>
        <img src='/assets/search.svg' aria-hidden="true" class="search-icon">
    </button>
</div>

<button *ngIf="Mobile" class="btn mobile-search-icon" type="button" (click)="showModal = true" aria-haspopup="true"
    aria-label="Open search">
    <img src='/assets/search.svg' aria-hidden="true">
</button>

<app-modal *ngIf='showModal' (close)='closeModal()' headerText='Search' icon='/assets/close.svg'>
    <app-search-container />
</app-modal>