<div class='subheader-wrapper' [ngClass]='classes'>
  <button type='button' class='step-cta' *ngIf="type === 'algorithm'" (click)='closeAlgorithm()'>
    <span>Algorithm</span>
    <img src='../../../assets/close-algorithm-view.svg' alt='close icon' />
  </button>
  <button type='button' class='algorithm-cta' *ngIf="type==='step'" (click)='openAlgorithm()'>
    <div class="algorithm-cta-label">
      <img src='../../../assets/algorithm-cta-icon1.svg' alt='algorithm icon' />
    <span>Algorithm view</span>
    </div>
    <span class="algorithm-cta-chevron"><img src='../../../assets/double-chevron-left.svg' alt='double chevron left'/></span>
  </button>
</div>

<ng-template #breadCrumbItem>
  <span class='breadcrumb-separator'>/</span>
</ng-template>
