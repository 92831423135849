import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import ButtonComponent from 'src/app/components/button/button.component';
import { RadioGroupModule } from 'src/app/components/radio-group/radio-group.module';
import { RadioOptions } from 'src/app/components/radio-group/RadioOptionsModel';
import { StepType } from 'src/lib/step/step';

@Component({
  selector: 'app-step-cta',
  standalone: true,
  imports: [CommonModule, ButtonComponent, RadioGroupModule],
  templateUrl: './step-cta.component.html',
  styleUrls: ['./step-cta.component.scss'],
})
export class StepCTAComponent {
  @Input() options: RadioOptions[] | null = [];
  @Input() type: StepType = StepType.QUESTION;
  @Output() clicked: EventEmitter<number> = new EventEmitter();

  onClick(optionIndex: number = 0) {
    this.clicked.emit(optionIndex);
  }
}
