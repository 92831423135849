<div class='modal-backdrop' (click)='closeModal()'></div>
<div class='modal'>
  <div class='modal-dialog'>
    <div class='modal-content'>
      <div class='modal-header'>
        <div class='header-wrapper'>
          <app-link [label]='title' (onClick)='goToManifestation.emit()' />
          <button
            id='close-btn'
            type='button'
            #closeBtn
            aria-label='Close'
            role='button'
            (click)='closeModal()'
          ><img alt='close icon' src='/assets/close.svg' /></button>
        </div>
      </div>
      <div class='modal-body'>
        <ul>
          <li *ngFor='let statement of problemStatements'>{{statement}}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
