import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { ofType, createEffect } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as SearchActions from './search.actions';
import { of } from 'rxjs';
import { SearchService } from '../search.service';

@Injectable()
export class SearchEffects {
  constructor(
    private actions$: Actions,
    private searchService: SearchService
  ) {}

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.search),
      mergeMap((action) =>
        this.searchService.search(action.searchText).pipe(
          map((searchResults) =>
            SearchActions.searchSuccess({ searchResults })
          ),
          catchError((error) => of(SearchActions.searchFailure({ error })))
        )
      )
    )
  );

  saveRecentSearch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActions.saveRecentSearch),
        tap((action) => {
          const search = action.searchText;
          this.searchService.saveRecentSearch(search);
        })
      ),
    { dispatch: false }
  );

  loadRecentSearches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.loadRecentSearches),
      map(() => {
        const recentSearches = this.searchService.getRecentSearches();
        return SearchActions.loadRecentSearchesSuccess({ recentSearches });
      })
    )
  );

  removeRecentSearch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActions.removeRecentSearch),
        tap((action) => {
          this.searchService.removeRecentSearch(action.recentSearch);
        })
      ),
    { dispatch: false }
  );

  clearSearchHistory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActions.clearSearchHistory),
        tap(() => {
          this.searchService.clearRecentSearches();
        })
      ),
    { dispatch: false }
  );
}
