import { Algorithm } from '../algorithm';
import { createReducer, on } from '@ngrx/store';
import { getAlgorithmSuccess } from './algorithm.actions';

export interface AlgorithmState {
  entities: { [manifestationId: string]: Algorithm[] };
}

export const initialState: AlgorithmState = {
  entities: {},
};

export const algorithmReducer = createReducer(
  initialState,
  on(getAlgorithmSuccess, (state, { manifestationId, data }) => ({
    ...state,
    entities: {
      ...state.entities,
      [manifestationId]: data,
    },
  }))
);
