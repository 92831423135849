export const bundleTree = {
  data: [
    {
      id: 'c7e0c4fa-bcbb-450d-9b25-742090f17449',
      version: 1,
      title: 'Transient Loss of Consciousness',
      slug: 'bundle-transient-loss-of-consciousness',
      description: 'Bundle for loss of consciousness content',
      publishedAt: '2024-09-27T19:24:59.516Z',
      bundleType: 'problem_statement',
      home: {
        file_name: 'clinical-loss-of-consciousness-home.json',
      },
      illness_script: [
        { file_name: 'alcohol-poisoning.json' },
        { file_name: 'aortic-dissection.json' },
        { file_name: 'aortic-stenosis.json' },
        { file_name: 'arrhythmias.json' },
        { file_name: 'atrial-myxoma.json' },
        { file_name: 'atrioventricular-block.json' },
        { file_name: 'cardiac-syncope.json' },
        { file_name: 'cerebrovascular-disease.json' },
        { file_name: 'hypertrophic-cardiomyopathy.json' },
        { file_name: 'hypoglycemia.json' },
        { file_name: 'opioid-overdose.json' },
        { file_name: 'prosthetic-valve-dysfunction.json' },
        { file_name: 'pulmonary-embolism.json' },
        { file_name: 'rapid-supraventricular-tachycardia.json' },
        { file_name: 'seizure.json' },
        { file_name: 'sick-sinus-syndrome.json' },
        { file_name: 'structural-obstruction.json' },
        { file_name: 'subarachnoid-hemorrhage.json' },
        { file_name: 'subclavian-steal.json' },
        { file_name: 'traumatic-brain-injury.json' },
        { file_name: 'ventricular-tachycardia.json' },
      ],
      step: [
        { file_name: 'step-syncope-or-not.json' },
        { file_name: 'step-syncope-type.json' },
      ],
      working_ddx: [
        { file_name: 'working-ddx-cardiac.json' },
        { file_name: 'working-ddx-nonsyncope.json' },
        { file_name: 'working-ddx-orthostatic-syncope2.json' },
        { file_name: 'working-ddx-reflex-syncope.json' },
      ],
    },
  ],
};
