import { isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthInterceptor } from './http-interceptors/auth-interceptor';
import { HeaderService } from 'src/lib/header/header.service';
import { HeaderWrapperModule } from './components/header-wrapper/header-wrapper.module';
import { ScrollService } from '../lib/base/scroll.service';
import { RedirectComponent } from './redirect/redirect.component';
import { AuthService } from '../lib/auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ResponseInterceptor } from './http-interceptors/response.interceptor';
import { WorkingDDxTableModule } from './components/working-ddx-table/working-ddx-table.module';

@NgModule({
  declarations: [AppComponent, RedirectComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: isDevMode(), // Restrict extension to log-only mode
    }),
    HeaderWrapperModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    WorkingDDxTableModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    HeaderService,
    ScrollService,
    CookieService,
    AuthService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
