import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'app-list-item',
  standalone: true,
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  imports: [CommonModule],
})
export class ListItemComponent {
  @ViewChild('button') button!: ElementRef;

  @Input() setTabindex!: number;
  @Input()
  title = 'List Item';

  @Input()
  icon = '';

  @Input()
  altText = '';

  @Input()
  iconCTA = '../../../assets/chevron-right.svg';

  @Input()
  iconCTALabel = 'arrow icon';

  @Output()
  onClick = new EventEmitter<Event>();

  @Output()
  onIconCTAClick = new EventEmitter<Event>();

  onButtonClick(event: Event) {
    this.onClick.emit(event);
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === ' ' || event.key === 'Enter')
      this.button.nativeElement.click();
  }

  onIconClick(event: Event) {
    event.stopPropagation();
    this.onIconCTAClick.emit(event);
  }
}
