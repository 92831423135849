import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HierarchyNode, ContentNode, NodeTemplate } from './hierarchy.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataFetchService {
  constructor(private http: HttpClient) {}

  getHierarchy(manifestationId: string): Observable<HierarchyNode> {
    return this.http
      .get<HierarchyNode>(
        `${environment.fileBaseUrl}/${manifestationId}/en_us/1/hierarchy.json`
      )
      .pipe(
        map((response) => {
          return response;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching hierarchy:', error);
          return of(null as unknown as HierarchyNode);
        })
      );
  }

  getJsonFile<T>(path: string): Observable<T> {
    return this.http.get<T>(`${environment.fileBaseUrl}/${path}`).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching JSON file:', error);
        return of(null as unknown as T);
      })
    );
  }

  fetchContentNodes(filePath: string): Observable<ContentNode[]> {
    return this.getJsonFile<any>(filePath).pipe(
      map((fileData) => {
        return fileData.content as ContentNode[];
      }),
      catchError((error) => {
        console.error('Error fetching content nodes:', error);
        return of([]);
      })
    );
  }

  findNode(node: HierarchyNode, id: string): HierarchyNode | null {
    const flatList: HierarchyNode[] = [];
    flattenNodes(node);

    function flattenNodes(currentNode: HierarchyNode) {
      if (currentNode.template !== NodeTemplate.ILLNESS_SCRIPT) {
        flatList.push(currentNode);
      }
      if (currentNode.children && currentNode.children.length > 0) {
        for (const child of currentNode.children) {
          flattenNodes(child);
        }
      }
    }
    return flatList.find((listNode) => listNode.id === id) ?? null;
  }

  findStepNode(node: HierarchyNode, stepId: string): HierarchyNode | null {
    if (node.id === stepId) {
      return node;
    }
    for (const child of node.children) {
      const result = this.findStepNode(child, stepId);
      if (result) {
        return result;
      }
    }
    console.error(
      `Step node with id ${stepId} not found in node with id ${node.id}`
    );
    return null;
  }
  findNodesByTemplate(node: HierarchyNode, template: string): HierarchyNode[] {
    const result: HierarchyNode[] = [];
    if (node.template === template) {
      result.push(node);
    }
    for (const child of node.children) {
      result.push(...this.findNodesByTemplate(child, template));
    }
    if (result.length === 0) {
      console.error(
        `No nodes found with template ${template} in node with id ${node.id}`
      );
    }
    return result;
  }

  findChildNodesByTemplate(node: HierarchyNode, template: string) {
    const result: HierarchyNode[] = [];
    for (const child of node.children) {
      if (child.template === template) {
        result.push(child);
      }
    }
    return result;
  }

  mapContentNodesToString = (
    contentNodes: ContentNode[],
    fieldName: string
  ): string => {
    return contentNodes
      .filter((node) => node.fieldName === fieldName)
      .map((node) => decodeURIComponent(node.data as string))
      .join('');
  };

  mapContentNodesToBoolean = (
    contentNodes: ContentNode[],
    fieldName: string,
    content: string
  ): boolean => {
    return contentNodes
      .filter((node) => node.fieldName === fieldName)
      .some((node) => Array.isArray(node.data) && node.data.includes(content));
  };
}
