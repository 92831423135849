import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WorkingDDxState } from './working-ddx.reducer';

export const workingDDxFeatureKey = 'WorkingDDx';

export const selectWorkingDDxState =
  createFeatureSelector<WorkingDDxState>(workingDDxFeatureKey);

export const selectWorkingDDx = createSelector(
  selectWorkingDDxState,
  (state: WorkingDDxState) => state.workingDDx
);
