import {
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { register } from 'swiper/element/bundle';
import { Swiper, SwiperOptions } from 'swiper/types';
import { ImageViewModel } from './images-carousel.types';
import { ModalComponent } from '../modal/modal.component';
import PinchZoom from 'pinch-zoom-js';

@Component({
  selector: 'app-images-carousel',
  standalone: true,
  imports: [CommonModule, ModalComponent],
  templateUrl: './images-carousel.component.html',
  styleUrls: ['./images-carousel.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ImagesCarouselComponent {
  @ViewChild('swiperDetailRef') protected _swiperDetailRef:
    | ElementRef
    | undefined;

  @Input() mode: 'horizontal' | 'grid' = 'horizontal';
  imagesLoaded = 0;
  @Input() size: 'normal' | 'small' = 'normal';
  @Input() images: ImageViewModel[] = [];
  lastFocusedElement!: HTMLElement | null;
  @Output() imagesLoadedEmitter = new EventEmitter<number>();

  detailIndex = 1;
  showDetail = false;
  zoomInstances: PinchZoom[] = [];

  constructor(private changeDetector: ChangeDetectorRef) {
    register();
  }

  onImageLoad() {
    this.imagesLoaded += 1;
    this.imagesLoadedEmitter.emit(this.imagesLoaded);
  }

  onKeyDown(event: KeyboardEvent, image: ImageViewModel) {
    this.lastFocusedElement = document.activeElement as HTMLElement;
    if (event.key === ' ' || event.key === 'Enter') {
      this.openDetail(image);
    }
  }

  openDetail(image: ImageViewModel) {
    this.showDetail = true;
    this.changeDetector.detectChanges();
    this.initializeDetailSwiper(image);
  }

  closeDetail() {
    this.zoomInstances.forEach((i) => {
      i.destroy();
    });

    this.showDetail = false;
    this.changeDetector.detectChanges();
    this.lastFocusedElement?.focus();
  }

  previous() {
    this.detailSwiperRef.slidePrev();
  }

  next() {
    this.detailSwiperRef.slideNext();
  }

  private initializeDetailSwiper(image: ImageViewModel) {
    const options: SwiperOptions = {
      grabCursor: false,
      allowTouchMove: false,
      loop: true,
      on: {
        init: () => {
          const zoomContainers = document.querySelectorAll(
            '.swiper-zoom-container'
          );
          zoomContainers.forEach((zoomContainer: any) => {
            this.zoomInstances.push(new PinchZoom(zoomContainer, {}));
          });
        },
        transitionStart: (s) => {
          this.detailIndex = s.realIndex + 1;
        },
      },
      initialSlide: this.images.indexOf(image),
    };

    const swiperEl = this._swiperDetailRef?.nativeElement;
    Object.assign(swiperEl, options);

    swiperEl.initialize();
    this.detailIndex = this.images.indexOf(image) + 1;
  }

  private get detailSwiperRef(): Swiper {
    return (this._swiperDetailRef?.nativeElement as any).swiper;
  }
}
