<div class='app-wrapper' [class.gray-bg]="applyStyle" [class.gradient]='gradient'>
  <header class='header-wrapper'>
    <app-header-wrapper />
  </header>

  <main class='content-wrapper'>
    <div class='container'>
      <router-outlet></router-outlet>
    </div>
  </main>
</div>