import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: 'button.component.html',
  styleUrls: ['./button.component.scss'],
})
export default class ButtonComponent {
  @Input()
  type:
    | 'primary'
    | 'secondary'
    | 'outline-blue'
    | 'outline-white'
    | 'white'
    | 'disabled'
    | 'disabled-secondary' = 'primary';

  @Input()
  size: 'small' | 'medium' | 'large' | 'full-width' = 'large';

  @Input()
  label = 'Button';

  @Output()
  onClick = new EventEmitter<Event>();

  @Input()
  icon?: any;

  public get classes(): string[] {
    return ['button', `button--${this.size}`, `button--${this.type}`];
  }

  get isDisabled(): boolean {
    return this.type === 'disabled' || this.type === 'disabled-secondary';
  }
}
