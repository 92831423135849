<div ngbAccordion [ngClass]="classes">
	<div ngbAccordionItem [collapsed]='!openedByDefault'>
		<h2 ngbAccordionHeader>
			<button ngbAccordionButton class="accordion-button">
          	<img alt="" *ngIf='icon' [src]="'assets/' + icon + '.svg'" />
          	{{ title }}
      		</button>
		</h2>
		<div ngbAccordionCollapse>
			<div ngbAccordionBody>
				<div class="text-box">
					<ng-content></ng-content>
				</div>
			</div>
		</div>
	</div>
</div>
